import { type FC, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Form,
  FormPasswordField,
  FormSubmitButton,
  GridContainer,
  GridItem,
  LoadingSpinner,
} from '@cofenster/web-components';

import { useUser } from '../../../contexts/user/useUser';
import { useGotoDeactivatedUser } from '../../../hooks/navigation/useGotoDeactivatedUser';
import { useCheckSso } from '../../../hooks/useCheckSso';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

const initialValues = { password: '' };

type Values = { password: string };

const validationSchema = Yup.object().shape({
  password: Yup.string().trim().required('i18n.form.error.password.required'),
});

const useOnSubmit = (email: string) => {
  const { signin } = useUser();
  const tracking = useWebManagerTracking();
  const gotoDeactivatedUser = useGotoDeactivatedUser();

  return useCallback(
    async ({ password }: Values) => {
      const result = await signin(email, password);
      if (result?.__typename === 'InvalidCredentialsError') {
        if (result?.type === 'INVALID_CREDENTIALS') {
          tracking.trackEvent({ event: 'FailedUserSignIn' });
          throw new Error('i18n.signin.form.error.invalidCredentials');
        }
        if (result?.type === 'DEACTIVATED_USER') gotoDeactivatedUser();
        if (result?.type === 'DELETED_USER') gotoDeactivatedUser();
      } else if (result?.__typename === 'User') {
        tracking.trackEvent({ event: 'UserSignIn', details: { userId: result.id } });
      }
    },
    [email, tracking, signin, gotoDeactivatedUser]
  );
};

export type SignInFormProps = {
  email: string;
};

export const SignInForm: FC<SignInFormProps> = ({ email }) => {
  const onSubmit = useOnSubmit(email);
  const { state } = useLocation();
  const { loading, authProvider } = useCheckSso(email, state?.redirect);

  // Until we know whether the password is needed or SSO is about to kick-in, we should display a loading spinner to
  // avoid flashing the password field for SSO-enrolled accounts.
  if (loading || authProvider) {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <LoadingSpinner />
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer spacing={0}>
        <GridItem xs={12}>
          <FormPasswordField
            label="i18n.signin.form.password.placeholder"
            placeholder="i18n.signin.form.password.placeholder"
            id="sign-in-password"
            name="password"
            disabled={loading}
            data-testid="password-input"
          />
        </GridItem>
        <GridItem xs={12}>
          <FormSubmitButton autoDisable fullWidth data-testid="password-continue-button">
            {loading ? <LoadingSpinner size={32} /> : 'i18n.signin.form.submit'}
          </FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
