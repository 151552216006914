import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  FormTextField,
  Spacing,
  Text,
} from '@cofenster/web-components';

import { type ProjectFolder, useCreateProjectFolder } from '../../../api/hooks/projectFolder/useCreateProjectFolder';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { useI18n } from '../../../i18n/useI18n';

type Values = {
  name: string;
};

const useValidationSchema = () => {
  const { translate } = useI18n();
  const validations = {
    name: Yup.string()
      .trim()
      .max(250, translate('form.error.generic.maxLength', { max: 250 }))
      .required('i18n.form.error.generic.required'),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);
  return baseSchema;
};

const useInitialValues = (name: string) => {
  return useMemo<Values>(
    () => ({
      name,
    }),
    [name]
  );
};

const useSubmit = (
  closeDialog: () => unknown,
  projectFolderId: string,
  onFolderCreated?: CreateProjectFolderDialogProps['onFolderCreated'],
  teamId?: string
) => {
  const createProjectFolder = useCreateProjectFolder();
  const tracking = useWebManagerTracking(teamId);

  const trackFolderCreated = useCallback(
    (data: ProjectFolder) =>
      tracking.trackEvent({
        event: 'folderCreated',
        details: {
          teamId: data.team?.id,
          teamName: data.team?.name,
          folderId: data.id,
          folderName: data.name,
          folderType: data.team ? 'team' : 'private',
        },
      }),
    [tracking]
  );

  return useCallback(
    async (values: Values) => {
      const response = await createProjectFolder(projectFolderId, values);
      const projectFolder = response.data?.createProjectFolder;

      closeDialog();

      if (projectFolder) {
        onFolderCreated?.(projectFolder);
        trackFolderCreated(projectFolder);
      }
    },
    [createProjectFolder, closeDialog, trackFolderCreated, projectFolderId, onFolderCreated]
  );
};

export type CreateProjectFolderDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  projectFolderId: string;
  onFolderCreated?: (projectFolder: ProjectFolder) => unknown;
  teamId?: string;
};

export const CreateProjectFolderDialog: FC<CreateProjectFolderDialogProps> = ({
  isOpen,
  closeDialog,
  projectFolderId,
  onFolderCreated,
  teamId,
}) => {
  const initialValues = useInitialValues('');
  const validationSchema = useValidationSchema();
  const onSubmit = useSubmit(closeDialog, projectFolderId, onFolderCreated, teamId);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="create-folder-dialog"
      title="i18n.dialogs.createProjectFolderDialog.headline"
    >
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <DialogContent>
          <Spacing bottom={3}>
            <Text variant="l" color="grey600" component="p">
              i18n.dialogs.createProjectFolderDialog.description
            </Text>
          </Spacing>

          <FormTextField
            id="folderName"
            name="name"
            label="i18n.dialogs.CreateProjectFolderDialog.label"
            placeholder="Folder name"
            autoComplete="off"
            autoFocus
            data-testid="folder-name-input"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <FormSubmitButton autoDisable fullWidth data-testid="create-folder-dialog-button">
            i18n.dialogs.createProjectFolderDialog.button
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
