import type { FC, ReactNode } from 'react';

import { type AudioAsset, usePollingAudioAsset } from '../../../../api/hooks/audioAsset/usePollingAudioAsset';

type Props = {
  audioAssetId?: string;
  children: (audioAsset: AudioAsset | undefined) => ReactNode;
};

export const PollingAudioAsset: FC<Props> = ({ children, audioAssetId }) => {
  const polledAudioAsset = usePollingAudioAsset(audioAssetId);

  return children(audioAssetId ? (polledAudioAsset ?? undefined) : undefined);
};
