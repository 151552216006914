import { type FC, useCallback, useState } from 'react';
import * as Yup from 'yup';

import { Button, Form, FormSubmitButton, FormTextField, GridContainer, GridItem } from '@cofenster/web-components';

import { RouterButton } from '../../../components/button/RouterButton';

import { SignInForm } from './SignInForm';

const initialValues = { email: '' };

type Values = typeof initialValues;

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('i18n.form.error.email.format')
    .matches(/@[\w-.]+\.[a-z]{2,}$/, 'i18n.form.error.email.format')
    .required('i18n.form.error.email.required'),
});

export const CheckEmailForm: FC = () => {
  const [email, setEmail] = useState<string>();
  const onSubmit = useCallback(async (values: Values) => {
    setEmail(values.email);
  }, []);

  return (
    <>
      {email ? (
        <>
          <SignInForm email={email} />
          <GridContainer mt={1} display="flex" justifyContent="space-between">
            <GridItem>
              <Button fullWidth onClick={() => setEmail(undefined)} variant="tertiary">
                i18n.global.cancel
              </Button>
            </GridItem>
            <GridItem>
              <RouterButton fullWidth variant="tertiary" to="forgotPassword" data-testid="forgot-password-link">
                i18n.signin.form.forgot
              </RouterButton>
            </GridItem>
          </GridContainer>
        </>
      ) : (
        <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <GridContainer spacing={0}>
            <GridItem xs={12}>
              <FormTextField
                id="email"
                name="email"
                label="i18n.signin.form.email.placeholder"
                placeholder="i18n.signin.form.email.placeholder"
                data-testid="email-input"
                autoCapitalize="none"
              />
            </GridItem>
            <GridItem minWidth={168} ml="auto">
              <FormSubmitButton autoDisable fullWidth data-testid="email-continue-button">
                i18n.global.continue
              </FormSubmitButton>
            </GridItem>
          </GridContainer>
        </Form>
      )}
    </>
  );
};
