import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSelectableBoxInput,
  FormSubmitButton,
  FormTextField,
  Icon,
  RemainingCharacterCounter,
  Spacing,
  Text,
  Typography,
  styled,
} from '@cofenster/web-components';

import { useCreateTeam } from '../../../api/hooks/team/useCreateTeam';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { useI18n } from '../../../i18n/useI18n';

const MAX_NAME_LENGTH = 50;

const Section = styled('div')({});

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const SectionContent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export type TeamType = 'private' | 'public';

type Values = {
  name: string;
  public: TeamType;
};

const useValidationSchema = () => {
  const { translate } = useI18n();
  const validations = {
    name: Yup.string()
      .trim()
      .max(MAX_NAME_LENGTH, translate('form.error.generic.maxLength', { max: MAX_NAME_LENGTH }))
      .required('i18n.form.error.generic.required'),
    public: Yup.mixed<TeamType>().required(),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);
  return baseSchema;
};

const useInitialValues = () => {
  return useMemo<Values>(
    () => ({
      name: '',
      public: 'public',
    }),
    []
  );
};

const useSubmit = (closeDialog: () => unknown, gotoProjectFolder: CreateTeamDialogProps['gotoProjectFolder']) => {
  const createTeam = useCreateTeam();
  const tracking = useWebManagerTracking();

  return useCallback(
    async (values: Values) => {
      const isPublic = values.public === 'public';

      const result = await createTeam({ name: values.name, public: isPublic });

      if (result.data?.createTeam.__typename === 'FieldError') {
        throw new Error('i18n.form.error.teamName.exists');
      }

      closeDialog();
      if (result.data?.createTeam) {
        tracking.trackEvent({
          event: 'teamSpaceCreated',
          details: {
            teamId: result.data.createTeam.id,
            teamName: result.data.createTeam.name,
            teamType: result.data.createTeam.public ? 'public' : 'private',
            teamRootFolderId: result.data.createTeam.rootProjectFolder.id,
          },
        });
        gotoProjectFolder(result.data.createTeam.rootProjectFolder.id);
      }
    },
    [createTeam, closeDialog, tracking, gotoProjectFolder]
  );
};

export type CreateTeamDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  gotoProjectFolder: (folderId: string) => unknown;
};

export const CreateTeamDialog: FC<CreateTeamDialogProps> = ({ isOpen, closeDialog, gotoProjectFolder }) => {
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();
  const onSubmit = useSubmit(closeDialog, gotoProjectFolder);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="create-team-dialog"
      title="i18n.dialogs.createTeamDialog.headline"
    >
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <DialogContent>
          <Spacing bottom={3}>
            <Text variant="l" color="grey600" component="p">
              i18n.dialogs.createTeamDialog.description
            </Text>
          </Spacing>

          <Section>
            <SectionTitle variant="h5" component="h3">
              Name
            </SectionTitle>
            <SectionContent>
              <FormTextField
                maxRows={2}
                id="teamNewName"
                name="name"
                label="i18n.team.name"
                placeholder="i18n.team.name"
                autoFocus
                maxLength={MAX_NAME_LENGTH}
                autoComplete="off"
                inputProps={{
                  'aria-describedby': 'teamUpdateName-counter',
                }}
                InputProps={{
                  endAdornment: (
                    <RemainingCharacterCounter name="name" id="teamUpdateName-counter" maxChars={MAX_NAME_LENGTH} />
                  ),
                }}
                data-testid="create-team-name-field"
              />
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle variant="h5" component="h3">
              i18n.team.access
            </SectionTitle>

            <SectionContent>
              <Alert severity="info" icon={<Icon type="InfoIcon" size="m" />}>
                i18n.team.access.info
              </Alert>

              <FormSelectableBoxInput
                name="public"
                id="public-team"
                data-testid="public-team-input"
                type="radio"
                value="public"
                title="i18n.team.public"
                description="i18n.team.public.info"
                mainIcon={<Icon type="UsersIcon" />}
              />
              <FormSelectableBoxInput
                name="public"
                id="private-team"
                data-testid="private-team-input"
                type="radio"
                value="private"
                title="i18n.team.private"
                description="i18n.team.private.info"
                mainIcon={<Icon type="LockIcon" />}
              />
            </SectionContent>
          </Section>
        </DialogContent>
        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <FormSubmitButton autoDisable fullWidth type="submit" data-testid="create-team-dialog-save">
            i18n.global.save
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
