import { type FC, useCallback } from 'react';

import {
  AspectRatioBox,
  Button,
  Card,
  DropzoneStatus,
  Form,
  FormToggleSwitch,
  Icon,
  IconButton,
  PopoverMenuItem,
  Typography,
  styled,
  useUpload,
  withPopoverMenu,
} from '@cofenster/web-components';

import type { ContributionRequestList } from '../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import { useUpdateContributionRequestList } from '../../../api/hooks/contributionRequestList/useUpdateContributionRequestList';
import { usePollingVideoAsset } from '../../../api/hooks/videoAsset/usePollingVideoAsset';
import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useGoToRecordBriefingVideo } from '../../../hooks/navigation/useGoToRecordBriefingVideo';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { useDeleteVideoBriefing } from '../../RecordVideoBriefing/hooks/useDeleteVideoBriefing';

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginRight: 'auto',
}));

const StyledButton = styled(Button)(() => ({
  whiteSpace: 'nowrap',
}));

const BriefingImageAsset = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'cover',
  alignSelf: 'stretch',
  justifySelf: 'stretch',
  borderRadius: theme.shape['borderRadius-l'],
}));

const BriefingEmptyStateImage = styled('img')(({ theme }) => ({
  height: '100%',
  objectFit: 'cover',
  alignSelf: 'stretch',
  justifySelf: 'stretch',
  borderRadius: theme.shape['borderRadius-l'],
  maxHeight: 104,
}));

const PlayButtonContainer = styled('div')(() => ({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const PlayButton = styled(IconButton)(() => ({
  opacity: 0,
  borderRadius: '50%',
  transition: 'opacity 200ms',
  '*:hover > &, *:focus > &, &:focus': {
    opacity: 1,
  },
}));

export const VideoBriefingCard: FC<{ projectId: string; list: ContributionRequestList }> = ({ projectId, list }) => {
  const goToRecordVideoBriefing = useGoToRecordBriefingVideo(projectId, list.id);
  const pollingVideoAsset = usePollingVideoAsset(list.briefing?.video?.id, list.briefing.video);
  const { getUpload } = useUpload();
  const upload = getUpload('video', list.id);

  const { openDialog } = useDialogs();
  const openBriefingVideoModal = () => {
    if (!pollingVideoAsset) return;
    openDialog('PreviewBriefingVideoDialog', {
      video: pollingVideoAsset,
      contributionRequestListId: list.id,
    });
  };

  const { deleteVideoBriefing } = useDeleteVideoBriefing(list.id);
  const confirmDelete = useConfirmDialog({
    title: 'i18n.projectContributionConfiguration.videoBriefing.confirmDelete.title',
    content: 'i18n.dialogs.DeleteAssetDialog.text',
    confirm: 'i18n.global.delete',
  });

  const tracking = useWebManagerTracking();
  const onDeleteClick = useCallback(async () => {
    if (await confirmDelete()) {
      await deleteVideoBriefing();
      tracking.trackEvent({
        event: 'requestBriefingAssetDeleted',
        details: { requestListId: list.id },
      });
    }
  }, [deleteVideoBriefing, confirmDelete, tracking, list.id]);

  return (
    <Card>
      <Row>
        {pollingVideoAsset || upload ? (
          <>
            <AspectRatioBox ratio={1} width={164}>
              {pollingVideoAsset?.videoUrl && pollingVideoAsset.thumbnailUrl ? (
                <>
                  <BriefingImageAsset alt="" src={pollingVideoAsset.thumbnailUrl} />
                  <PlayButtonContainer>
                    <PlayButton
                      icon="PlayIcon"
                      iconSize="s"
                      iconColor="white"
                      label="i18n.global.play"
                      backgroundColor="blurred"
                      size="medium"
                      iconWeight="fill"
                      onClick={openBriefingVideoModal}
                    />
                  </PlayButtonContainer>
                </>
              ) : (
                <DropzoneStatus
                  status={upload ? 'uploading' : 'processing'}
                  text=""
                  onCancel={onDeleteClick}
                  cancelIconButtonTopRight={1}
                />
              )}
            </AspectRatioBox>
          </>
        ) : (
          <picture>
            <source src="/assets/images/briefing-empty-state.png" />
            <BriefingEmptyStateImage alt="" src="/assets/images/briefing-empty-state.webp" />
          </picture>
        )}
        <TextContainer>
          <Typography variant="h5">i18n.projectContributionConfiguration.briefing.title</Typography>
          <Typography>i18n.projectContributionConfiguration.briefing.description</Typography>
        </TextContainer>
        <div>
          {pollingVideoAsset || upload ? (
            <>
              {pollingVideoAsset?.videoUrl && (
                <BriefingEnableDisable briefingEnabled={list.briefing.enabled} contributionRequestListId={list.id} />
              )}
            </>
          ) : (
            <StyledButton variant="secondary" startIcon={<Icon type="RecordIcon" />} onClick={goToRecordVideoBriefing}>
              i18n.projectContributionConfiguration.briefing.recordVideoButton
            </StyledButton>
          )}
        </div>
        {pollingVideoAsset?.videoUrl && (
          <VideoBriefingMoreActions
            briefingVideoUrl={pollingVideoAsset?.videoUrl ?? undefined}
            onDeleteClick={onDeleteClick}
            contributionRequestListId={list.id}
          />
        )}
      </Row>
    </Card>
  );
};

const VideoBriefingMoreActions: FC<{
  briefingVideoUrl: string | undefined;
  onDeleteClick: VoidFunction;
  contributionRequestListId: string;
}> = ({ briefingVideoUrl, onDeleteClick, contributionRequestListId }) => {
  const tracking = useWebManagerTracking();
  const onDownloadClick = useCallback(() => {
    tracking.trackEvent({
      event: 'requestBriefingAssetDownloaded',
      details: {
        requestListId: contributionRequestListId,
      },
    });
  }, [tracking, contributionRequestListId]);

  const MoreButton = withPopoverMenu(IconButton, {
    children: [
      <PopoverMenuItem
        key="download"
        icon="DownloadIcon"
        onClick={onDownloadClick}
        target="_blank"
        href={briefingVideoUrl}
        component="a"
        download
      >
        i18n.global.download
      </PopoverMenuItem>,
      <PopoverMenuItem key="delete" icon="TrashIcon" color="negative" onClick={onDeleteClick}>
        i18n.global.delete
      </PopoverMenuItem>,
    ],
  });

  return (
    <MoreButton icon="ThreeDotsIcon" label="i18n.projectContributionConfiguration.videoBriefing.moreOptionsLabel" />
  );
};

type Values = {
  briefingEnabled: boolean;
};

const BriefingEnableDisable: FC<{ contributionRequestListId: string; briefingEnabled: boolean }> = ({
  contributionRequestListId,
  briefingEnabled,
}) => {
  const { updateContributionRequestList } = useUpdateContributionRequestList();
  const tracking = useWebManagerTracking();

  const onSubmit = useCallback(
    async (values: Values) => {
      await updateContributionRequestList(contributionRequestListId, values);
      tracking.trackEvent({
        event: values.briefingEnabled ? 'requestBriefingEnabled' : 'requestBriefingDisabled',
        details: { requestListId: contributionRequestListId },
      });
    },
    [updateContributionRequestList, contributionRequestListId, tracking]
  );

  return (
    <Form initialValues={{ briefingEnabled }} onSubmit={onSubmit}>
      {({ submitForm, isSubmitting }) => (
        <FormToggleSwitch
          id="briefingEnabled"
          name="briefingEnabled"
          onChange={() => submitForm()}
          disabled={isSubmitting}
        />
      )}
    </Form>
  );
};
