import { type FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner, useUrlParameter } from '@cofenster/web-components';

import { useProjectFolder } from '../../api/hooks/projectFolder/useProjectFolder';
import { AppLayout } from '../../components/page/Layout';
import { CurrentTeamProvider } from '../../contexts/currentTeam/CurrentTeamProvider';
import { ListViewTypeProvider } from '../../contexts/listViewType/ListViewTypeProvider';
import { ProjectFolderProvider } from '../../contexts/projectFolder/ProjectFolderProvider';
import { SearchFilterProvider } from '../../contexts/searchFilter/SearchFilterProvider';
import type { ProjectsSearchFilters } from '../../contexts/searchFilter/useProjectsSearchFilters';
import { useUser } from '../../contexts/user/useUser';
import type { ProjectFolderRouteParams } from '../../routes';
import { NoAccessLayout } from '../NoAccess';

import { ProjectFolderContent } from './ProjectFolderContent';

export const ProjectFolder: FC = () => {
  const { folderId } = useParams() as ProjectFolderRouteParams;
  const { user } = useUser();

  const isPersonal = folderId === 'personal';
  const projectFolderId = isPersonal ? user?.rootProjectFolder.id : folderId;

  const { projectFolder, error } = useProjectFolder(projectFolderId);
  const [statusFromQuery] = useUrlParameter<ProjectsSearchFilters['status']>('ACTIVE', 'status');

  const isArchive = projectFolder?.parentFolder ? !!projectFolder.archivedAt : statusFromQuery === 'ARCHIVED';
  const title = !projectFolder?.parentFolder
    ? (projectFolder?.team?.name ?? 'i18n.projectFolder.personal')
    : projectFolder.name;

  if (error) {
    return <NoAccessLayout resource="ProjectFolder" />;
  }

  return (
    <AppLayout documentTitle={title} MainContentAreaComponent={Fragment}>
      <CurrentTeamProvider team={projectFolder?.team ?? undefined}>
        <SearchFilterProvider resultsPerPage={30}>
          <ListViewTypeProvider id="projectAndFolders">
            {projectFolder ? (
              <ProjectFolderProvider projectFolder={projectFolder}>
                <ProjectFolderContent projectFolder={projectFolder} isArchive={isArchive} title={title} />
              </ProjectFolderProvider>
            ) : (
              <LoadingSpinner />
            )}
          </ListViewTypeProvider>
        </SearchFilterProvider>
      </CurrentTeamProvider>
    </AppLayout>
  );
};
