import { VIDEO_FORMATS } from '@cofenster/constants';
import {
  AspectRatioBox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  EmptyState,
  type GotoCallback,
  LoadingSpinner,
  NativeVideoPlayer,
  Typography,
  styled,
  useSnackbars,
} from '@cofenster/web-components';
import { RemotionPlayer, RemotionPlayerControls, type RemotionPlayerRef } from '@cofenster/web-remotion-player';
import { type FC, useCallback, useEffect, useRef, useState } from 'react';
import { useCreateProjectFromProjectTemplate } from '../../../api/hooks/projectTemplate/useCreateProjectFromProjectTemplate';
import { useProjectTemplateRenderDetails } from '../../../api/hooks/projectTemplate/useProjectTemplateRenderDetails';
import type { ProjectTemplate } from '../../../api/hooks/projectTemplate/useProjectTemplates';
import { useAvailableTeamsForPermission } from '../../../hooks/useAvailableTeamsForPermission';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { ClearAspectRatioBox } from '../../../pages/ProjectVideo/RenderProgress/ClearAspectRatioBox';
import { routes } from '../../../routes';
import { Sentry } from '../../../sentry';
import { RootFolderSelect } from '../../form/RootFolderSelect';

const FlexCenterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  gap: theme.spacing(2),
}));

type ProjectTemplatePreviewDialogProps = {
  closeDialog: () => unknown;
  isOpen: boolean;
  projectTemplate: ProjectTemplate;
  goto: GotoCallback;
  personalFolderId: string;
  enforcedProjectId?: string;
};

const StyledDialogActions = styled(DialogActions)(() => ({
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

const TeamSelectWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  textWrap: 'nowrap',
  marginRight: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const StyledAspectRatioBox = styled(AspectRatioBox)(({ theme }) => ({
  borderRadius: theme.shape['borderRadius-l'],
}));

export const ProjectTemplatePreviewDialog: FC<ProjectTemplatePreviewDialogProps> = ({
  closeDialog,
  isOpen,
  projectTemplate,
  goto,
  personalFolderId,
  enforcedProjectId,
}) => {
  const videoUrl = projectTemplate.project.videoAsset?.videoUrl;
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking();

  const [createProjectFromProjectTemplate, { loading }] = useCreateProjectFromProjectTemplate();
  const availableTeams = useAvailableTeamsForPermission('ProjectCreate');
  const [selectedRootFolderId, setSelectedRootFolderId] = useState(
    enforcedProjectId ? enforcedProjectId : (availableTeams[0]?.rootProjectFolder.id ?? personalFolderId)
  );

  useEffect(() => {
    tracking.trackEvent({
      event: 'projectTemplatePreviewOpened',
      details: {
        projectTemplateId: projectTemplate.id,
        projectTemplateName: projectTemplate.project.name,
      },
    });
  }, [tracking, projectTemplate]);

  const handleConfirm = useCallback(async () => {
    try {
      const result = await createProjectFromProjectTemplate(projectTemplate.id, {
        projectFolderId: selectedRootFolderId,
      });
      const projectId = result.data?.createProjectFromProjectTemplate.id;
      if (projectId) {
        goto(routes.projectEdit, { projectId });
        tracking.trackEvent({
          event: 'projectCreated',
          details: { projectId, sourceProjectTemplateId: projectTemplate.id },
        });
      }
      closeDialog();
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar({
        children: 'i18n.global.error.generic.unknown',
        variant: 'error',
      });
    }
  }, [
    createProjectFromProjectTemplate,
    projectTemplate,
    selectedRootFolderId,
    goto,
    closeDialog,
    openSnackbar,
    tracking,
  ]);

  // RemotionPlayer onPlay is triggered twice
  const playTracked = useRef(false);
  const trackOnPlay = useCallback(() => {
    if (playTracked.current) return;
    playTracked.current = true;
    tracking.trackEvent({
      event: 'projectTemplateVideoPlayed',
      details: { projectTemplateId: projectTemplate.id, projectTemplateName: projectTemplate.project.name },
    });
  }, [tracking, projectTemplate]);

  const { aspectRatio } = VIDEO_FORMATS[projectTemplate.project.videoFormat];

  return (
    <Dialog open={isOpen} onClose={closeDialog} title={projectTemplate.project.name} size="l" withHeaderBottomBorder>
      <DialogContent>
        <FlexCenterContainer>
          <AspectRatioBox ratio={16 / 9} width="100%">
            <ClearAspectRatioBox>
              <StyledAspectRatioBox ratio={aspectRatio} height="100%">
                {videoUrl ? (
                  <NativeVideoPlayer src={videoUrl} actions={['FULLSCREEN']} onPlay={trackOnPlay} />
                ) : (
                  <PreviewProjectTemplate projectTemplate={projectTemplate} onPlay={trackOnPlay} />
                )}
              </StyledAspectRatioBox>
            </ClearAspectRatioBox>
          </AspectRatioBox>

          <Typography variant="l">{projectTemplate.description}</Typography>
        </FlexCenterContainer>
      </DialogContent>
      <StyledDialogActions>
        {!enforcedProjectId && (
          <TeamSelectWrapper>
            <Typography>i18n.home.createProjectDialog.teamSelect.before</Typography>
            <RootFolderSelect
              label="i18n.home.createProjectDialog.teamSelect.label"
              name="destinationTeam"
              teams={availableTeams}
              privateRootFolderId={personalFolderId}
              value={selectedRootFolderId}
              onChange={setSelectedRootFolderId}
              selectProps={{ pb: 0 }}
            />
          </TeamSelectWrapper>
        )}
        <Button variant="tertiary" onClick={closeDialog} disabled={loading}>
          i18n.global.cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm} loading={loading}>
          i18n.projectTemplates.projectTile.actions.useTemplate
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

const PreviewProjectTemplate: FC<{ projectTemplate: ProjectTemplate; onPlay?: VoidFunction }> = ({
  projectTemplate,
  onPlay,
}) => {
  const [playerRef, setPlayerRef] = useState<RemotionPlayerRef | null>(null);

  const { projectTemplateRenderDetails } = useProjectTemplateRenderDetails(projectTemplate.id);
  const renderDescription = projectTemplateRenderDetails?.renderDescription;
  const project = projectTemplateRenderDetails?.project;

  if (!renderDescription || !project) return <LoadingSpinner />;

  return (
    <>
      {renderDescription.totalDurationInSeconds === 0 ? (
        <EmptyState
          iconType="XCircleIcon"
          title="i18n.projectTemplates.previewTemplate.noScenes.title"
          description="i18n.projectTemplates.previewTemplate.noScenes.description"
        />
      ) : (
        <RemotionPlayer
          ref={setPlayerRef}
          renderDescription={renderDescription}
          bundleUrl={project.template.previewBundleUrl}
          templateIdentifier={project.template.templateIdentifier}
          onPlay={onPlay}
        />
      )}

      <RemotionPlayerControls
        size="s"
        duration={renderDescription.totalDurationInSeconds}
        playerRef={{ current: playerRef }}
      />
    </>
  );
};
