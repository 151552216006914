import { Card, LoadingSpinner, styled } from '@cofenster/web-components';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useProjectSubtitlesLanguages } from '../../../api/hooks/project/useProjectSubtitlesLanguages';
import { useRenderJobsByProject } from '../../../api/hooks/renderJob/useRenderJobsByProject';
import { useScenes } from '../../../api/hooks/scene/useScenes';
import type { Project } from '../ProjectVideoContent';
import { ExportListCard } from './ExportListCard';

const StyledList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  listStyleType: 'none',

  'li:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}));

export const ExportsList: FC<{ project: Project; refetchProject: VoidFunction }> = ({ project, refetchProject }) => {
  const { languages, loading: loadingLanguages } = useProjectSubtitlesLanguages(project.id);
  const { renderJobs, loading: loadingJobs } = useRenderJobsByProject(project.id, refetchProject);
  const { scenes } = useScenes(project.id);
  const firstSceneId = useMemo(() => scenes.find((scene) => !scene.excluded)?.id, [scenes]);
  const loading = loadingLanguages || loadingJobs;

  const languageToRenderJob = useMemo(() => {
    if (!renderJobs) return {};
    return Object.fromEntries(renderJobs.map((job) => [job.subtitlesLanguage ?? 'no-language', job]));
  }, [renderJobs]);

  if (loading) return <LoadingSpinner size={16} />;
  if (!languages || !renderJobs) return null;

  return (
    <Card>
      <StyledList>
        <ExportListCard project={project} subtitlesLanguage={null} renderJob={languageToRenderJob['no-language']} />
        {project.hasSceneSubtitlesAvailable &&
          languages.map((language) => (
            <ExportListCard
              key={language}
              project={project}
              subtitlesLanguage={language}
              renderJob={languageToRenderJob[language]}
              firstSceneId={firstSceneId}
            />
          ))}
      </StyledList>
    </Card>
  );
};
