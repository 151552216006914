import { IconButton as MuiIconButton, type IconButtonProps as MuiIconButtonProps, styled } from '@mui/material';
import { type ComponentType, type FC, type PropsWithChildren, forwardRef } from 'react';

import type { I18nParams } from '../../../services/i18n';
import type { BrandColor } from '../../../theming/theme';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { Icon, type IconProps } from '../../assets/icons/Icon';
import { SpinningIcon } from '../../assets/icons/SpinningIcon';
import { Tooltip, type TooltipProps } from '../../feedback/Tooltip';
import { VisuallyHidden } from '../../utilities/VisuallyHidden';

export type IconButtonProps = MuiIconButtonProps & {
  // Base props
  // biome-ignore lint/suspicious/noExplicitAny: no idea, there might be a better way
  component?: ComponentType<any>;

  // Icon props
  icon: IconProps['type'];
  iconSize?: IconProps['size'];
  iconColor?: IconProps['color'];
  iconWeight?: IconProps['weight'];

  // Interactivity props
  hoverColor?: BrandColor;
  backgroundColor?: BrandColor | 'blurred';
  hoverBackgroundColor?: BrandColor;

  // Accessibility props
  label: string;
  i18nParams?: I18nParams;
  tooltip?: TooltipProps['title'] | null;
  tooltipPlacement?: TooltipProps['placement'];

  // Button props
  type?: 'submit' | 'button' | 'reset';

  // Anchor props
  href?: string;
  target?: '_blank';
  download?: boolean;

  loading?: boolean;
};

const StyledIconButton = styled(
  MuiIconButton,
  preventForwardProps(['backgroundColor', 'hoverColor', 'hoverBackgroundColor', 'borderRadius', 'isActive'])
)<{
  backgroundColor: IconButtonProps['backgroundColor'];
  hoverColor: IconButtonProps['hoverColor'];
  hoverBackgroundColor: IconButtonProps['hoverBackgroundColor'];
  href?: IconButtonProps['href'];
}>(({ theme, backgroundColor, hoverColor, hoverBackgroundColor }) => ({
  color: 'currentcolor',
  backgroundColor:
    backgroundColor === 'blurred' ? 'rgb(10 36 44 / 0.3)' : theme.palette.brand?.[backgroundColor ?? 'transparent'],
  backdropFilter: backgroundColor === 'blurred' ? 'blur(13px)' : undefined,
  borderRadius: theme.shape.borderRadius,

  svg: {
    transition: '150ms',
  },

  '&:hover, &:focus': {
    color:
      backgroundColor === 'blurred'
        ? theme.palette.brand.white
        : hoverColor
          ? theme.palette.brand[hoverColor]
          : 'inherit',
    backgroundColor:
      backgroundColor === 'blurred'
        ? theme.palette.brand.carbon
        : hoverBackgroundColor
          ? theme.palette.brand[hoverBackgroundColor]
          : 'rgb(0 0 0 / 0.04)',

    svg: {
      color: 'currentColor',
      fill: 'currentColor',
    },
  },

  '&:focus-visible': theme.mixins.focusRing,
}));

const MaybeTooltip: FC<
  PropsWithChildren<{
    tooltip?: IconButtonProps['tooltip'];
    placement?: IconButtonProps['tooltipPlacement'];
    i18nParams?: IconButtonProps['i18nParams'];
    disabled?: boolean;
    tooltipProps?: Partial<TooltipProps>;
  }>
> = ({ children, tooltip, disabled, placement, i18nParams, tooltipProps }) =>
  tooltip ? (
    <Tooltip title={tooltip} i18nParams={i18nParams} placement={placement} {...tooltipProps}>
      {
        // A disabled button does not fire event, which causes the tooltip not
        // to work; which is why we add an extra wrapping div so the tooltip
        // remain usable even though the wrapped button is disabled.
        disabled ? <div>{children}</div> : children
      }
    </Tooltip>
  ) : (
    <>{children}</>
  );

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps & { tooltipProps?: Partial<TooltipProps> }>(
  function IconButton(
    {
      icon,
      iconSize = 'm',
      iconColor = 'currentColor',
      iconWeight = 'regular',
      hoverColor,
      backgroundColor,
      hoverBackgroundColor,
      label,
      i18nParams,
      tooltip = label,
      tooltipPlacement = 'top',
      loading,
      onClick,
      href,
      tooltipProps,
      ...props
    },
    ref
  ) {
    return (
      <MaybeTooltip
        tooltip={tooltip}
        i18nParams={i18nParams}
        placement={tooltipPlacement}
        disabled={props.disabled}
        tooltipProps={tooltipProps}
      >
        <StyledIconButton
          {...props}
          ref={ref}
          backgroundColor={backgroundColor}
          hoverColor={hoverColor}
          aria-disabled={loading ? true : undefined}
          onClick={loading ? undefined : onClick}
          href={loading ? undefined : href}
          hoverBackgroundColor={hoverBackgroundColor}
          type={'to' in props || 'href' in props ? undefined : (props.type ?? 'button')}
          className={[props.className, loading ? 'Mui-disabled' : undefined].filter(Boolean).join(' ')}
        >
          {loading ? (
            <SpinningIcon type="LoadingIcon" size={iconSize} color={iconColor} speed="fast" weight={iconWeight} />
          ) : (
            <Icon type={icon} size={iconSize} color={iconColor} weight={iconWeight} />
          )}
          <VisuallyHidden i18nParams={i18nParams}>{label}</VisuallyHidden>
        </StyledIconButton>
      </MaybeTooltip>
    );
  }
);
