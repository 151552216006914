import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGotoProjectFolder = (folderId?: string, archive?: boolean) => {
  const goto = useGoto();
  return useCallback(
    (_folderId?: string, _archive?: boolean) => {
      goto(
        routes.projectFolder,
        {
          folderId: _folderId ?? folderId,
        },
        (_archive ?? archive) ? { search: { status: 'ARCHIVED' } } : undefined
      );
    },
    [goto, folderId, archive]
  );
};
