import { useMemo } from 'react';

import {
  type CreateProjectFromProjectTemplateMutationVariables,
  useCreateProjectFromProjectTemplateMutation,
} from '../../generated';

export const useCreateProjectFromProjectTemplate = () => {
  const [createProjectFromProjectTemplate, metadata] = useCreateProjectFromProjectTemplateMutation({
    refetchQueries: ['ProjectTemplates'],
    awaitRefetchQueries: true,
  });

  return useMemo(
    () =>
      [
        (
          id: CreateProjectFromProjectTemplateMutationVariables['id'],
          input: CreateProjectFromProjectTemplateMutationVariables['input']
        ) => {
          return createProjectFromProjectTemplate({ variables: { id, input } });
        },
        metadata,
      ] as const,
    [createProjectFromProjectTemplate, metadata]
  );
};
