import { type FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { IntercomButton } from '@cofenster/intercom';
import {
  Form,
  FormPasswordField,
  FormSubmitButton,
  GridContainer,
  GridItem,
  TextLink,
  Typography,
  styled,
  useLocalizedWebsiteUrl,
} from '@cofenster/web-components';

import { useResetPassword } from '../../api/hooks/user/useResetPassword';
import { useGotoDeactivatedUser } from '../../hooks/navigation/useGotoDeactivatedUser';
import { useGotoHomeHandler } from '../../hooks/navigation/useGotoHomeHandler';
import type { SetPasswordRouteParams } from '../../routes';

const initialValues = {
  password: '',
  passwordConfirmation: '',
};

type Values = typeof initialValues;

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  password: Yup.string().trim().required('i18n.form.error.password.required'),
  passwordConfirmation: Yup.string()
    .trim()
    .required('i18n.form.error.passwordRepeat.required')
    .oneOf([Yup.ref('password')], 'i18n.form.error.passwordRepeat.match'),
});

const StyledLink = styled(TextLink)(({ theme }) => ({
  color: theme.palette.brand.blue,
  textDecoration: 'underline',
}));

const useOnSubmit = () => {
  const { token } = useParams() as SetPasswordRouteParams;
  const resetPassword = useResetPassword();
  const gotoDeactivatedUser = useGotoDeactivatedUser();
  const gotoHome = useGotoHomeHandler();

  return useCallback(
    async ({ password }: Values) => {
      if (!token) return;

      const result = await resetPassword(token, password);
      const resultTypename = result.data?.resetPassword.__typename;
      if (
        resultTypename === 'DeactivatedUserError' ||
        resultTypename === 'DeletedUserError' ||
        resultTypename === 'UserNotFoundError'
      ) {
        return gotoDeactivatedUser();
      }
      if (resultTypename === 'InvalidTokenError') {
        throw new Error('i18n.form.error.token.setPassword');
      }
      if (result.data?.resetPassword.__typename === 'WeakPasswordError') {
        throw new Error('i18n.form.error.password.weak');
      }

      if (result.data?.resetPassword.__typename === 'PasswordTokenResponse') return gotoHome();
    },
    [token, resetPassword, gotoDeactivatedUser, gotoHome]
  );
};

export const SetPasswordForm: FC = () => {
  const onSubmit = useOnSubmit();
  const termsHref = useLocalizedWebsiteUrl('TERMS_OF_USE');
  const dataHref = useLocalizedWebsiteUrl('PRIVACY_POLICY');

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnChange>
      <GridContainer spacing={0} mt={-4}>
        <GridItem xs={12}>
          <FormPasswordField
            id="password"
            name="password"
            label="i18n.reset.form.password.placeholder"
            placeholder="i18n.reset.form.password.placeholder"
          />
        </GridItem>
        <GridItem xs={12}>
          <FormPasswordField
            id="passwordConfirmation"
            name="passwordConfirmation"
            label="i18n.reset.form.passwordConfirmation.placeholder"
            placeholder="i18n.reset.form.passwordConfirmation.placeholder"
          />
        </GridItem>
        <GridItem xs={12} mb={4}>
          <Typography
            i18nParams={{
              tnc: (chunks) => (
                <StyledLink href={termsHref} target="blank" rel="noopener noreferrer">
                  {chunks}
                </StyledLink>
              ),
              data: (chunks) => (
                <StyledLink href={dataHref} target="blank" rel="noopener noreferrer">
                  {chunks}
                </StyledLink>
              ),
            }}
          >
            i18n.set.tnc
          </Typography>
        </GridItem>
        <GridItem>
          <IntercomButton variant="tertiary">i18n.auth.button.needHelp</IntercomButton>
        </GridItem>
        <GridItem ml="auto">
          <FormSubmitButton autoDisable fullWidth>
            i18n.set.form.submit
          </FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
